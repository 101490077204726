import { Fragment } from 'react';
import { pageMap } from './PageMap';

type Props = {
  pageObject: any;
}

const Location = (props: Props) => {
  const { pageObject } = props;

  let currentPageObject = pageObject;

  return (
    <Fragment>
      {currentPageObject.locationComponents.map((item: any) => {
        return pageMap[item.component as 'cactusHero']({...currentPageObject.pageProperties, ...item.props as any})
      })}
    </Fragment>
  )
}

export default Location