import React from "react";
import {
  BrowserRouter as Router,
  useRoutes,
} from "react-router-dom";
import Home from './Home';
import pageObject from './Bitlify/pageObject.json';
import Location from './Location';
import './fonts/Aleo.css'
import './fonts/Mulish.css'
import './fonts/Oswald.css'

const RoutedApp = () => {
  let routes = useRoutes([
    { path: "/", element: <Home pageObject={pageObject}/> },
    { path: "/location", element: <Location pageObject={pageObject}/> }
  ]);
  return routes;
};

function App() {
  return (
    <main className={pageObject.pageProperties.font}>
      <Router>
        <RoutedApp />
      </Router>
    </main>
  );
}

export default App;
