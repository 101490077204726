import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
if (path) {
  window.history.replaceState(null, "", path);
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);