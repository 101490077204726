import { Fragment, useEffect } from 'react'
import { pageMap } from './PageMap';

type Props = {
  pageObject: any;
}

const Home = (props: Props) => {
  const { pageObject } = props;

  useEffect(() => {
    const buttons = document.querySelectorAll('a#button');
    buttons.forEach((button) => {
      console.log(button)
      if (button.innerHTML.slice(0,11) !== 'Contáctanos'){
        button.removeAttribute('href');
        button.setAttribute('href', 'https://creator.bitlify.mx');
      }
    })
  }, [])
  
  let currentPageObject = pageObject;

  return (
    <Fragment>
      {currentPageObject.homeComponents.map((item: any) => {
        return pageMap[item.component as 'cactusHero']({...currentPageObject.pageProperties, ...item.props} as any)
      })}
    </Fragment>
  )
}

export default Home