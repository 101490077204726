import {
  Navbar,
  Contact,
  BenefitsWithCards,
  CTA,
  FindUs,
  Footer,
  Options,
  Sell,
  BenefitsWithIcons,
  Content,
  HazelHero,
  CactusHero,
  OakHero,
  OptionsWithImgs,
  Testimonials,
  SocialProof,
  Video
} from '@hugo-osorio/components-library';

import {
  BenefitsWithCardsProps,
  CactusHeroProps,
  CTAProps,
  FindUsProps,
  FooterProps,
  HazelHeroProps,
  NavbarProps,
  OakHeroProps,
  OptionsProps,
  SellProps,
  BenefitsWithIconsProps,
  ContactProps,
  ContentProps,
  OptionsWithImgsProps,
  TestimonialsProps,
  SocialProofProps,
  VideoProps
} from '@hugo-osorio/components-library/dist/utils/types';

export const pageMap = {
  'cactusHero': (props: CactusHeroProps) => <CactusHero {...props} />,
  'hazelHero': (props: HazelHeroProps) => <HazelHero {...props} />,
  'oakHero': (props: OakHeroProps) => <OakHero {...props} />,
  'navbar': (props: NavbarProps) => <Navbar {...props} />,
  'options': (props: OptionsProps) => <Options {...props} />,
  'optionsWithImgs': (props: OptionsWithImgsProps) => <OptionsWithImgs {...props} />,
  'contact': (props: ContactProps) => <Contact {...props} />,
  'content': (props: ContentProps) => <Content {...props} />,
  'benefitsWithCards': (props:BenefitsWithCardsProps) => <BenefitsWithCards {...props} />,
  'benefitsWithIcons': (props:BenefitsWithIconsProps) => <BenefitsWithIcons {...props} />,
  'cta': (props: CTAProps) => <CTA {...props} />,
  'sell': (props: SellProps) => <Sell {...props} />,
  'findUs': (props: FindUsProps) => <FindUs {...props} />,
  'testimonials': (props: TestimonialsProps) => <Testimonials {...props} />,
  'socialProof': (props: SocialProofProps) => <SocialProof {...props} />,
  'video': (props: VideoProps) => <Video {...props} />,
  'footer': (props: FooterProps) => <Footer {...props} />,
}
